<template>
    <div class="saveSellOutMould">
        <div class="main-flex">
            <edit-btns class="btn-box" 
            :bnts="['goBack']"
            @goBackClick="$router.back()"/>
            <!-- <div class="title-box">
                限量销售模板设置
            </div> -->
        </div>
        <div class="content-box">
            <div class="flex-box">
                <div class="left-table">
                    <div class="title">模板列表</div>
                    <div class="table-box" ref="tempNameTable">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageTempTableData"
                            :row-class-name="({row})=>row.Tmplate_ID==currentInfo?.Tmplate_ID?'current-row':''"
                            @row-click="currentChange"
                            >
                            <el-table-column label="序号" width="50" type="index" :index="(index)=>index+(pageIndex-1)*pagesize+1" />
                            <el-table-column prop="Tmplate_Code" label="模板编号" align="left" min-width="100" ></el-table-column>
                            <el-table-column prop="Tmplate_Name" label="模板姓名" align="left" min-width="100" ></el-table-column>
                            <el-table-column label="操作" width="80">
                                <template #default="scope">
                                    <i class="iconfont icon-a-beizhu2" @click="edit(scope.row)" ></i>
                                    <i class="iconfont icon-shanchu" @click="delData(scope.row,scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="bottom-search-box">
                        <div class="add-bnt" @click="add()"><i class="iconfont icon-jia"></i>添加模板</div>
                        <div class="search-box" style="flex: 1;">
                            <key-board-input class="search"
                                type="text" v-model="tempSearchText" placeholder="按名称检索"
                                isBoard isKeyEnterClose isBlurClose
                                :keyOptions="{isKeyDownEnter:true}"
                            ></key-board-input>
                        </div>
                        <div class="page-box-bottom">
                            <page-turning class="last-page" :pageCount="tempPageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                            <span class="page-number">{{pageIndex}}/{{tempPageCount}}页</span>
                            <page-turning class="next-page" :pageCount="tempPageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                        </div>
                    </div>
                </div>
                <div class="right-table">
                    <div class="btn-box">
                        <div>
                             操作模板:
                             <span v-if="currentInfo">{{currentInfo.Tmplate_Name}}</span>
                             <span class="red" v-else>请点击左边列表选择模板</span>
                        </div>
                        <div class="right-bnt">
                            <div class="bnt excel-bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出 </div>
                            <div class="bnt del-bnt" @click="delKitFood()"><i class="iconfont icon-shanchu"></i>批量删除</div>
                        </div>
                    </div>
                    <div class="table-box" ref="tempFoodsTable">
                        <el-table class="el-table--scrollable-y" ref="kitFoods" style="width: 100%;height:100%"  
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        :data="pagetempFoods"
                        @selection-change="(val)=>{multipleFoodsTable=val}"
                        >
                            <el-table-column label="序号" width="50" type="index" :index="(index)=>index+(pageIndex2-1)*pagesize2+1" />
                            <el-table-column type="selection" width="40" />
                            <el-table-column prop="Food_Code" label="菜品编码" min-width="80" ></el-table-column>
                            <el-table-column prop="Food_Name" label="菜品名称" min-width="100" ></el-table-column>
                            <el-table-column prop="Food_Size" label="单位" min-width="100" ></el-table-column>
                            <el-table-column prop="SubCategory_Name" label="小类名称" min-width="80" ></el-table-column>
                            <el-table-column label="操作" width="60">
                                <template #default="scope">
                                    <i class="iconfont icon-shanchu" @click="delKitFood(scope.row,scope.$index)"></i>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="bottom-search-box">
                        <div class="add-bnt" @click="addFood()"><i class="iconfont icon-jia"></i>添加菜品</div>
                        <div class="fill-box"></div>
                        <div class="search-box">
                            <key-board-input class="search"
                                type="text" v-model="foodText" placeholder="菜品模糊检索"
                                isBoard isKeyEnterClose 
                                :keyOptions="{isKeyDownEnter:true}"
                            ></key-board-input>
                        </div>
                        <div class="page-box-bottom">
                            <page-turning class="last-page" :pageCount="tempFoodsPageCount" v-model="pageIndex2" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                            <span class="page-number">{{pageIndex2}}/{{tempFoodsPageCount}}页</span>
                            <page-turning class="next-page" :pageCount="tempFoodsPageCount" v-model="pageIndex2"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 模板设置 -->
        <modal-load :isShow="addMouldIsShow" >
            <add-mould-model :isShow="addMouldIsShow" :data="tempNameInfo" @closeModel="addMouldIsShow=false" @confirm="EditMould"></add-mould-model>
        </modal-load>
        <!-- 选择菜品绑定模板 -->
        <modal-load :isShow="foodIsShow" :isUnload="true">
            <food-model :isShow="foodIsShow" :foods="posFoods" :subTitle="'模板名称：'+currentInfo?.Tmplate_Name" :selectList="currentInfo.TemplateLimitFoods" @closeModel="foodIsShow=false" @confirm="KitNameBindFood"></food-model>
        </modal-load>
    </div>
</template>

<script>
import addMouldModel from './addMouldModel/addMouldModel.vue'
import foodModel from './foodModel/foodModel.vue'
import XLSX from 'xlsx'

export default {
    name:"saveSellOutMould",
    components:{addMouldModel,foodModel},
    data(){
        return {
            //模板数据
            limitTempList:[],
            pageIndex:1,
            pagesize:5,
            //模板检索条件
            tempSearchText:"",
            //选中的模板
            currentInfo:null,

            pageIndex2:1,
            pagesize2:5,
            //模板检索条件
            foodText:"",
            //菜品数据
            posFoods:[],
            //多选菜品
            multipleFoodsTable:[],
            //模板设置 弹层
            addMouldIsShow:false,
            
            //选择菜品绑定厨师 弹层
            foodIsShow:false,
            //要修改的模板数据
            tempNameInfo:null
        }
    },
    computed:{
        //模板筛选数据
        tempTableFilter(){
            let text=(this.tempSearchText||"").trim().toUpperCase();
            if(text){
                return this.limitTempList.filter(it=>(it.Tmplate_Name||"").toUpperCase().indexOf(text)>=0);
            }
            return this.limitTempList;
        },
        //模板总页数
        tempPageCount(){
            if(this.tempTableFilter?.length>0){
                return Math.ceil(this.tempTableFilter.length/this.pagesize);
            }
            return 1;
        },
        //模板分页数据
        pageTempTableData(){
            return this.tempTableFilter.slice((this.pageIndex-1)*this.pagesize,this.pageIndex*this.pagesize);
        },
        //模板绑定的菜品 筛选数据
        tempFoodsFiler(){
            if(!this.currentInfo){
                return [];
            }
            let data=this.currentInfo.TemplateLimitFoods||[];
            let text=(this.foodText||"").trim().toLowerCase();
            if(text){
                data= data.filter(it=> (it.Food_Name||"").toLowerCase().indexOf(text)>=0 
                || (it.Food_PinYin||"").toLowerCase().indexOf(text)>=0 
                || (it.Food_Code||"").toLowerCase().indexOf(text)>=0);
            }
            return data;
        },
        /**分页数据 */
        pagetempFoods(){
            return this.tempFoodsFiler.slice((this.pageIndex2-1)*this.pagesize2,this.pageIndex2*this.pagesize2);
        },
        ///厨师绑定的菜品总页数
        tempFoodsPageCount(){
            if(this.tempFoodsFiler?.length>0){
                return Math.ceil(this.tempFoodsFiler.length/this.pagesize2);
            }
            return 1;
        },
        
    },
    mounted(){
        //加载所有菜品数据
        this.$cacheData.AllPosFoods().then((d)=>{
            this.posFoods=d||[];
        }).catch(e=>{
            console.log('e:',e);
            this.$message.error('未找到菜品数据');
        })
        
        this.$nextTick(()=>{
            this.loadData();
            this.setTempPageSize();
            this.setTempFoodsPageSize();
        });
        
    },
    watch:{
        tempSearchText(newVal){
            if(newVal){
                this.pageIndex=1;
            }
        },
        currentInfo(){
            this.pageIndex2=1;
        },
        kitFoodsPageCount(newVal){
            if(newVal<this.pageIndex2){
                this.pageIndex2=1;
            }
        },
        foodText(newVal){
            if(newVal){
                this.pageIndex2=1;
            }
        },
    },
    methods:{
        /**模板列表 计算 一页显示多少条数据 */
        setTempPageSize(){
            if(this.$refs.tempNameTable){
                this.$nextTick(()=>{
                    let height=this.$refs.tempNameTable.clientHeight - 30;
                    this.pagesize=parseInt(height/40);
                })
            }
        },
        /**模板菜品列表 计算 一页显示多少条数据 */
        setTempFoodsPageSize(){
            if(this.$refs.tempFoodsTable){
                this.$nextTick(()=>{
                    let height=this.$refs.tempFoodsTable.clientHeight - 30;
                    this.pagesize2=parseInt(height/40);
                })
            }
        },
        //加载数据
        loadData(){
            const loading = this.$loading({
                text: "获取限量菜品设置模板数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetLimitTemplate", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    this.limitTempList=d.ResponseBody;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("限量菜品设置模板数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        add(){
            this.addMouldIsShow=true;
            this.tempNameInfo=null;
        },
        edit(row){
            this.addMouldIsShow=true;
            this.tempNameInfo=row;
        },
        delData(row){
            this.$confirm("您确认删除模板吗？","提示", {
                confirmButtonText: "继续",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.DelLimitTemplate(row);
                    }
                },
            })
        },
        //删除模板
        DelLimitTemplate(row){
            const loading = this.$loading({
                text: "数据删除中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.DelLimitTemplate",{
                User_ID:userInfo.User_ID,
                Tmplate_ID:row.Tmplate_ID,
                Operator_Name:userInfo?.Login_Name,
                Tmplate_Timestamps:row.Tmplate_Timestamps
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("删除成功");
                    let index= this.limitTempList.findIndex(it=>it.Tmplate_ID==row.Tmplate_ID);
                    if(index>=0){//删除
                        this.limitTempList.splice(index,1);
                    }
                    
                    if(this.currentInfo?.Tmplate_ID==row.Tmplate_ID){
                        this.currentInfo=null;
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('模板删除失败：'+e.message);
                console.log('模板删除失败：',e);
            })
        },
        //选中模板
        currentChange(currentRow){
            this.currentInfo=currentRow;
            console.log(currentRow)
        },
        //添加菜品
        addFood(){
            if(!this.currentInfo){
                this.$message.warning("请先选择模板后在添加菜品!");
                return;
            }
            this.foodIsShow=true;
        },
        //新增或修改模板
        EditMould(info,foods){
            if(!info){return;}
            if(!info.Tmplate_Code){
                this.$message.warning("编号不能为空!");
                return;
            }
            if(!info.Tmplate_Name){
                this.$message.warning("姓名不能为空!");
                return;
            }
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            if(foods){
                foods.order('Food_Code','ASC');//菜品编号 升序
            }

            let param={
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Tmplate_ID:info.Tmplate_ID,
                Tmplate_Code:info.Tmplate_Code,
                Tmplate_Name:info.Tmplate_Name,
                TemplateLimitFoods:foods,
                Tmplate_Timestamps:info.Tmplate_Timestamps
            }
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.SetLimitTemplate",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.addMouldIsShow=false;
                    this.foodIsShow=false;
                    this.$message.success("保存成功");
                    console.log("保存成功",d.ResponseBody)
                    if(d.ResponseBody){
                        if(info.Tmplate_ID){//修改
                             this.limitTempList.find(it=>{
                                if(it.Tmplate_ID==info.Tmplate_ID){
                                    it.Tmplate_Code=d.ResponseBody.Tmplate_Code;
                                    it.Tmplate_Name=d.ResponseBody.Tmplate_Name;
                                    it.TemplateLimitFoods=d.ResponseBody.TemplateLimitFoods;
                                    it.Tmplate_Timestamps=d.ResponseBody.Tmplate_Timestamps;
                                    return true;
                                }
                                return false;
                             })
                        }else{//新增
                            if((this.limitTempList||[]).length==0){
                                this.limitTempList=[];
                            }
                            this.limitTempList.push(d.ResponseBody);
                        }
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('模板保存失败：'+e.message);
                console.log('模板保存失败：',e);
            })
        },
        //模板绑定菜品
        KitNameBindFood(list){
            if((list||[]).length==0){
                this.$message.warning("请先勾选要绑定的菜品!");
                return;
            }
            this.$confirm("您确认保存模板菜品信息吗?","提示", {
                confirmButtonText: "确认",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.EditMould(this.currentInfo,list)
                    }
                },
            })
        },
        //解除模板绑定菜品
        DelKitNameBindFood(AutoIDs){
            let list= this.currentInfo.TemplateLimitFoods.filter(it=>AutoIDs.indexOf(it.Food_ID)<0);

            this.EditMould(this.currentInfo,list)
        },
        //删除模板菜品
        delKitFood(row){
            let AutoIDs=[];
            if(row){
                AutoIDs=[row.Food_ID];
            }else if(this.multipleFoodsTable?.length>0){
                this.multipleFoodsTable.forEach(it=>{
                    AutoIDs.push(it.Food_ID);
                })
            }
            if(AutoIDs.length==0){
                this.$message.warning("请勾选要删除的菜品!");
                return;
            }
            this.$confirm(row?"您确认删除"+row.Food_Name+"吗？":"您确认删除模板绑定的菜品吗？","提示", {
                confirmButtonText: "确认",
                cancelButtonText:"取消",
                callback: (name) => {
                    if(name=='confirm'){
                        this.DelKitNameBindFood(AutoIDs);
                    }
                },
            })
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(!this.currentInfo){
                this.$message.warning("请先选择模板后在导出!");
                return;
            }
            var newWorkbook = XLSX.utils.book_new();

            let arr=[['菜品编码','菜品名称','单位','小类名称']];
            this.currentInfo?.TemplateLimitFoods?.forEach(it=>{
                arr.push([it.Food_Code,it.Food_Name,it.Food_Size,it.SubCategory_Name])
            })
                
            let wb = XLSX.utils.aoa_to_sheet(arr);
            XLSX.utils.book_append_sheet(newWorkbook,wb,'限量销售模板信息');
            try {
                XLSX.writeFile(newWorkbook,"限量销售模板信息.xlsx")
            } catch (e) {
                if (typeof console !== 'undefined') console.log(e)
            }
        },
    }
}
</script>

<style lang="scss">
    @import './saveSellOutMould.scss'
</style>